import { FormCSS, LoadingButtonCSS, ProjektentwicklerFormTitleCSS, Wrapper } from '../styled';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldRHF } from '../../UI/TextFieldRHF/TextFieldRHF';
import { yupResolver } from '@hookform/resolvers/yup';
import { projektentwicklerFormSchema } from '../../../validation';
import { useAlertContext } from '../../../context';
import { useRegisterDeveloperMutation } from '../../../graphql/generated';
import { ChevronRightIcon } from '../../icons';

type FormValues = {
  name: string;
  firma: string;
  phoneNumber: string;
  email: string;
  website: string | undefined;
  message: string | undefined;
};

const INIT_VALUE = {
  name: '',
  firma: '',
  phoneNumber: '',
  email: '',
  website: '',
  message: '',
};

export const ProjektentwicklerForm = () => {
  const { onOpenAlert } = useAlertContext();

  const methods = useForm<FormValues>({
    defaultValues: INIT_VALUE,
    resolver: yupResolver(projektentwicklerFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const [registerDeveloper, { loading }] = useRegisterDeveloperMutation({
    onCompleted: () => {
      reset(INIT_VALUE);
      onOpenAlert({
        title:
          'Ihre Anfrage ist bei uns erfolgreich eingegangen und wird schnellstmöglich bearbeitet',
      });
    },
    onError: (error) => {
      onOpenAlert({ title: 'Aufgetretener fehler', subTitle: error.message, error: true });
    },
  });

  const onSubmitRHF = (value: FormValues) => {
    registerDeveloper({
      variables: {
        input: {
          lastName: value.name,
          companyName: value.firma,
          phoneNumber: value.phoneNumber,
          email: value.email,
          website: value.website || '',
          message: value.message || '',
        },
      },
    });
  };

  return (
    <Wrapper>
      <ProjektentwicklerFormTitleCSS mb={'28px'}>
        Lassen Sie uns hier bitte Ihre Projektanfrage zukommen:
      </ProjektentwicklerFormTitleCSS>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <FormCSS>
            <TextFieldRHF name={'name'} placeholder={'Ihr Name*'} label={'Name'} />
            <TextFieldRHF name={'firma'} placeholder={'Ihre Firma*'} label={'Firma'} />
            <TextFieldRHF
              type={'tel'}
              name={'phoneNumber'}
              placeholder={'Ihre Telefonnummer für etwaige Rückfragen*'}
              label={'Telefonnummer'}
            />
            <TextFieldRHF
              name={'email'}
              placeholder={'Ihre E-Mail Adresse*'}
              label={'E-Mail Adresse'}
            />
            <TextFieldRHF
              name={'website'}
              placeholder={'Ihre Website mit Referenzen'}
              label={'Website'}
            />
            <TextFieldRHF
              name={'message'}
              placeholder={'Ihre Nachricht an uns'}
              label={'Nachricht'}
              multiline
              minRows={6}
              maxRows={6}
            />
          </FormCSS>
          <LoadingButtonCSS
            type={'submit'}
            variant={'submit'}
            endIcon={<ChevronRightIcon />}
            loading={loading}
            loadingPosition='end'
          >
            EINSENDEN
          </LoadingButtonCSS>
        </form>
      </FormProvider>
    </Wrapper>
  );
};
