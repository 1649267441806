import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldRHF } from '../../UI/TextFieldRHF/TextFieldRHF';
import { FormCSS, LoadingButtonCSS, Wrapper } from '../styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { drittRegisterFormSchema } from '../../../validation';
import { useRegisterGuarantorMutation } from '../../../graphql/generated';
import { useAlertContext } from '../../../context';
import { ChevronRightIcon } from '../../icons';

type FormValues = {
  email: string;
  password: string;
  repeatPassword: string;
};

const INIT_VALUE = {
  email: '',
  password: '',
  repeatPassword: '',
};

export const RegistrierenDrittForm = () => {
  const { onOpenAlert } = useAlertContext();

  const methods = useForm<FormValues>({
    defaultValues: INIT_VALUE,
    resolver: yupResolver(drittRegisterFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const [registerGuarantor, { loading }] = useRegisterGuarantorMutation({
    onCompleted: () => {
      reset(INIT_VALUE);
      onOpenAlert({ title: 'Erfolg' });
    },
    onError: (error) => {
      onOpenAlert({ title: 'Aufgetretener fehler', subTitle: error.message, error: true });
    },
  });

  const onSubmitRHF = (value: FormValues) => {
    registerGuarantor({
      variables: {
        input: {
          email: value.email,
          password: value.password,
        },
      },
    });
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <FormCSS>
            <TextFieldRHF
              name={'email'}
              placeholder={'Ihre E-Mail Adresse*'}
              label={'E-Mail Adresse'}
            />
            <TextFieldRHF
              name={'password'}
              type={'password'}
              placeholder={'Ihr Passwort*'}
              label={'Passwort'}
            />
            <TextFieldRHF
              name={'repeatPassword'}
              type={'password'}
              placeholder={'Passwort wiederholen*'}
              label={'Passwort wiederholen'}
            />
          </FormCSS>
          <LoadingButtonCSS
            type={'submit'}
            variant={'submit'}
            endIcon={<ChevronRightIcon />}
            sx={{
              marginTop: '28px',
            }}
            loading={loading}
            loadingPosition='end'
          >
            Sich Registrieren
          </LoadingButtonCSS>
        </form>
      </FormProvider>
    </Wrapper>
  );
};
