import * as yup from 'yup';
import { MESSAGES } from './messages';

export const projektentwicklerFormSchema = yup.object().shape({
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  firma: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  phoneNumber: yup
    .string()
    .matches(/^[\d ()+-]+$/, MESSAGES.INCORRECT_FORMAT)
    .required(MESSAGES.REQUIRE_MESSAGE),
  email: yup.string().email(MESSAGES.INCORRECT_EMAIL).required(MESSAGES.REQUIRE_MESSAGE),
  website: yup.string(),
  message: yup.string(),
});
